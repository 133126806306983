import { FC } from "react";

import {
  BaseComponentProps,
  KLanguageSwitcher as KLanguageSwitcherComponent,
} from "@keepeek/refront-components";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import JSCookies from "js-cookie";
import { useRouter } from "next/router";
import { parse } from "psl";

import { LOCALE_COOKIE_NAME } from "../../lib/i18n/i18next/i18next-instance";
import logger from "../../lib/logger-utils";

export type KLanguageSwitcher = BaseComponentProps;

const KLanguageSwitcher: FC<KLanguageSwitcher> = function (props) {
  const { locale: currentLocale, locales, pathname, asPath, query, push } = useRouter();

  const handleChangeLanguage = (event: SelectChangeEvent) => {
    const locale = event.target.value;
    // save selected language in cookie in order to keep it for the current session (and especially if we are on a compass page, we want to keep the language chosen through the other refront of the same domain)
    // need to extract the domain of the url => toto.tata.com -> tata.com
    const extractedDomain = parse(window.location.hostname);
    const domain =
      extractedDomain && extractedDomain["domain"] && extractedDomain["domain"].length > 0
        ? `${extractedDomain["domain"]}`
        : window.location.hostname;
    logger.debug(`Changing locale to ${locale}`);
    JSCookies.set(LOCALE_COOKIE_NAME, locale, {
      domain,
    });
    logger.debug(`Set locale cookie on ${domain} domain with value ${locale}`);
    push({ pathname, query }, asPath, { locale });
  };

  return (
    <KLanguageSwitcherComponent
      currentLocale={currentLocale || ""}
      locales={locales || []}
      onChange={handleChangeLanguage}
      {...props}
    />
  );
};

export function showKLanguageSwitcher(locales: string[] | undefined): boolean {
  return (locales && locales.length > 1) || false;
}

export default KLanguageSwitcher;
