import { FC } from "react";

import { useAuthLogin } from "@keepeek/commons";
import { BaseComponentProps, KButton, SanitizeHtml } from "@keepeek/refront-components";
import { Box, Link, Typography } from "@mui/material";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { Global } from "../../../models/configuration/global";
import { useAuthProps } from "../../../providers/auth/hooks/useAuthProps";
import { useConfiguration } from "../../../providers/config/hooks";
import { KIcon } from "../../common/KIcon";
import { CONFIGURATION_SECTION_PATH } from "../../layout/adminMenu";
import { StyledKKeycloakAutomaticAuth, classes } from "./styles";

export type KKeycloakAutomaticAuthProps = { helpLinkHref: string | undefined } & BaseComponentProps;

/**
 * Keepeek landing page automatic auth panel with button(s)
 */
const KKeycloakAutomaticAuth: FC<KKeycloakAutomaticAuthProps> = function ({
  helpLinkHref,
  className,
}) {
  const { keycloakConfiguration } = useConfiguration<Global>(CONFIGURATION_SECTION_PATH.GLOBAL) ?? {
    keycloakConfiguration: undefined,
  };
  const { loginUrl } = useAuthLogin(useAuthProps());
  const { t } = useTranslation();

  return (
    <StyledKKeycloakAutomaticAuth className={clsx("KKeycloakAutomaticAuth", className)}>
      <Typography className={classes.title} variant="h3" component="h2">
        <SanitizeHtml html={t("landing.page.login.title")} />
      </Typography>
      <Typography className={classes.subTitle} variant="subtitle2" component="p">
        <SanitizeHtml html={t("landing.page.login.subtitle")} />
      </Typography>
      {keycloakConfiguration &&
        keycloakConfiguration.idp &&
        keycloakConfiguration.idp.map((idp) => (
          <Box key={`${idp.key}`} className={classes.buttonContainer}>
            <KButton
              className={classes.button}
              data-testid="connectionButton"
              variant="outlined"
              size="large"
              color="primary"
              href={loginUrl(idp.key)}
              fullWidth
              // TODO : replace by auto_fix_high when the icon lib will be up to date
              startIcon={<KIcon>flash_auto</KIcon>}
            >
              {keycloakConfiguration &&
              keycloakConfiguration.idp &&
              keycloakConfiguration.idp.length > 1
                ? t("landing.page.connect.with.sso.name", { idp: idp.name })
                : t("landing.page.connect.with.sso")}
            </KButton>
          </Box>
        ))}
      {helpLinkHref && (
        <Link className={classes.helpLink} href={helpLinkHref}>
          {t("landing.page.login.help.link.label")}
        </Link>
      )}
    </StyledKKeycloakAutomaticAuth>
  );
};

export default KKeycloakAutomaticAuth;
