import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const PREFIX = "KLandingHeader";

export const classes = {
  root: `${PREFIX}-root`,
  logoContainer: `${PREFIX}-logoContainer`,
  title: `${PREFIX}-title`,
  kLanguageSwitcherContainer: `${PREFIX}-kLanguageSwitcherContainer`,
  kLanguageSwitcher: `${PREFIX}-kLanguageSwitcher`,
};

export const StyledKLandingHeader = styled(Box)(({ theme }) => ({
  [`&.${classes.root}`]: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr 1fr",
    gridColumnGap: theme.spacing(2),
    justifyItems: "center",
  },

  [`& .${classes.logoContainer}`]: {
    gridColumnStart: 2,
    display: "flex",
    alignItems: "flex-end",
    margin: 0,
  },

  [`& .${classes.title}`]: {
    fontSize: "1.1rem",
    marginLeft: theme.spacing(1),
  },

  [`& .${classes.kLanguageSwitcherContainer}`]: {
    marginLeft: "auto",
  },

  [`& .${classes.kLanguageSwitcher}`]: {
    "& .MuiInputBase-formControl": {
      fontWeight: "bold",
      "&:before": {
        display: "none",
      },
      "&:after": {
        display: "none",
      },
    },
  },
}));
