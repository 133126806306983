import { UseAuthProps } from "@keepeek/commons";
import { useRecoilValue } from "recoil";

import { kcGuestSelector } from "../../config/selectors/init";
import { useAuthRedirectUri } from "./useAuthRedirectUri";

export const useAuthProps = (): UseAuthProps => {
  const guest = useRecoilValue(kcGuestSelector);
  const { redirectUri } = useAuthRedirectUri();

  return { guest, redirectUri };
};
