export interface Landing {
    backgroundLogin?: BackgroundLogin;
    displayAppTitle?: boolean;
    fullHeightPanel?: boolean;
    logoCentered?:    boolean;
    logoLogin?:       BackgroundLogin;
    logoMaxHeight?:   number;
    panelPosition?:   PanelPosition;
}

export interface BackgroundLogin {
    id?:  string;
    url?: string;
}

export enum PanelPosition {
    Center = "center",
    Left = "left",
    Right = "right",
}
