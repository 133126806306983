import React, { FC, Fragment, ReactNode } from "react";

import { BaseComponentProps, SanitizeHtml } from "@keepeek/refront-components";
import { LayoutOverrideKey, WrapperOverrideKey } from "@keepeek/refront-customers";
import { Box, Hidden, Theme, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import EditionModal from "../../containers/FrontEdit/EditionModal";
import { getCustomerOverrideComponent } from "../../lib/overrides";
import { Landing, PanelPosition } from "../../models/configuration/layouts/landing";
import { Key } from "../../models/configuration/theme";
import { useConfiguration } from "../../providers/config/hooks";
import { configSectionThemeAssetSelector } from "../../providers/config/selectors";
import useFrontEdit from "../../providers/frontEdit/hooks/useFrontEdit";
import { getCurrentContentEditsSelector } from "../../providers/frontEdit/selectors/currentContentEdits";
import { CONFIGURATION_SECTION_PATH } from "./adminMenu";

export type LandingPageLayoutProps = {
  header: ReactNode;
  mainAuthMethod: ReactNode;
  bottomComponents: ReactNode[];
} & BaseComponentProps;

export type LandingPageLayoutWrapperProps = {
  landingConfiguration: Landing;
  background: string;
  // can be useful in a customer override context
  // to display the logo in the image section instead of the login header
  logoLogin?: string;
} & LandingPageLayoutProps;

const PREFIX = "LandingPageLayout";

export const classes = {
  panel: `${PREFIX}-panel`,
  panelTop: `${PREFIX}-panelTop`,
  spaced: `${PREFIX}-spaced`,
  subtitle: `${PREFIX}-subtitle`,
  panelBottom: `${PREFIX}-panelBottom`,
  image: `${PREFIX}-image`,
};

const getRootStyles = ({
  background,
  landingConfiguration: { fullHeightPanel, panelPosition },
  theme,
}: Pick<LandingPageLayoutWrapperProps, "background" | "landingConfiguration"> & {
  theme: Theme;
}) => {
  let style: any = {
    minHeight: "100vh",
    display: "flex",
  };

  // The image has to be in background if the panel is center horizontally
  // or doesn't occupy the full height
  if (
    (!fullHeightPanel && fullHeightPanel !== undefined) ||
    panelPosition === PanelPosition.Center
  ) {
    style = {
      ...style,
      alignItems: !fullHeightPanel ? "center" : "intial",
      backgroundImage: `url(${background})`,
      backgroundSize: "cover",
      backgroundPosition: "50% 50%",
    };
  }

  // Set the panel to its correct position
  switch (panelPosition) {
    case PanelPosition.Center:
      style = {
        ...style,
        justifyContent: "center",
        "& .LandingPageLayout-panel": {
          width: theme.spacing(75),
        },
      };
      break;
    case PanelPosition.Right:
      style = { ...style, flexDirection: "row-reverse" };
      break;
  }

  return style;
};

const StyledLandingPageLayoutWrapper = styled(Box)<
  Pick<LandingPageLayoutWrapperProps, "background" | "landingConfiguration">
>(({ theme, background, landingConfiguration }) => ({
  ...getRootStyles({ background, landingConfiguration, theme }),

  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
  [`& .${classes.panel}`]: {
    height: "auto",
    width: theme.spacing(100),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    backgroundColor: theme?.palette?.common?.white,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  [`& .${classes.spaced}`]: {
    padding: theme.spacing(2.5),
  },
  [`& .${classes.subtitle}`]: {
    fontSize: "1.3rem",
    lineHeight: "1.5rem",
    marginTop: theme.spacing(2),
  },
  [`& .${classes.image}`]: {
    height: "auto",
    width: "100%",
    backgroundSize: "cover",
    backgroundPosition: "50% 50%",
    backgroundImage: `url(${background})`,
  },
}));

const LandingPageLayout: FC<LandingPageLayoutProps> = function ({
  header,
  mainAuthMethod,
  bottomComponents,
  className,
}) {
  const landingConfiguration =
    useConfiguration<Landing>(CONFIGURATION_SECTION_PATH.LAYOUT_LANDING) ?? {};

  const { frontEdit } = useFrontEdit();

  const currentContentEdit = useRecoilValue(
    getCurrentContentEditsSelector({
      endpoint: CONFIGURATION_SECTION_PATH.LAYOUT_LANDING,
    }),
  );

  const [background] = useRecoilValue<string[]>(
    configSectionThemeAssetSelector(Key.ImageBackgroundLogin),
  );
  let backgroundSrc: string = background;
  if (landingConfiguration?.backgroundLogin && landingConfiguration?.backgroundLogin.url) {
    backgroundSrc = landingConfiguration?.backgroundLogin.url;
  }
  if (frontEdit && currentContentEdit) {
    backgroundSrc = currentContentEdit.data.backgroundLogin.url;
  }
  const [logoLogin] = useRecoilValue<string[]>(configSectionThemeAssetSelector(Key.ImageLogoLogin));

  let logoLoginSrc: string = logoLogin;
  if (landingConfiguration?.logoLogin && landingConfiguration?.logoLogin.url) {
    logoLoginSrc = landingConfiguration.logoLogin.url;
  }

  const CustomerLandingPageLayoutWrapper = getCustomerOverrideComponent(
    WrapperOverrideKey.LandingPageLayoutWrapper,
    LandingPageLayoutWrapper,
  );

  if (!landingConfiguration) {
    return null;
  }

  return (
    <CustomerLandingPageLayoutWrapper
      header={header}
      mainAuthMethod={mainAuthMethod}
      bottomComponents={bottomComponents}
      landingConfiguration={landingConfiguration}
      background={backgroundSrc}
      logoLogin={logoLoginSrc}
      className={className}
    />
  );
};

const LandingPageLayoutWrapper: FC<LandingPageLayoutWrapperProps> = function ({
  header,
  mainAuthMethod,
  bottomComponents,
  landingConfiguration,
  background,
  className,
}) {
  const { t } = useTranslation();
  return (
    <StyledLandingPageLayoutWrapper
      className={clsx("LandingPageLayout", className)}
      landingConfiguration={landingConfiguration}
      background={background}
      component="main"
    >
      <Box className={clsx("LandingPageLayout-panel", classes.panel)}>
        <Box className={clsx("LandingPageLayout-panelTop", classes.panelTop)}>
          <Box className={classes.spaced}>{header}</Box>
          <Box className={clsx("LandingPageLayout-subtitleContainer", classes.spaced)}>
            <Typography
              className={clsx("LandingPageLayout-subtitle", classes.subtitle)}
              component="div"
              variant={"subtitle1"}
            >
              <SanitizeHtml
                sanitizeOptions={{
                  allowVulnerableTags: true,
                  allowedTags: false,
                  allowedAttributes: false,
                }}
                html={t("landing.page.subtitle")}
              />
            </Typography>
          </Box>
          <Box className={classes.spaced}>{mainAuthMethod}</Box>
        </Box>
        <Box className={clsx("LandingPageLayout-panelBottom", classes.panelBottom)}>
          {bottomComponents.map((bottomComponent, index) => (
            <Fragment key={index}>{bottomComponent}</Fragment>
          ))}
        </Box>
      </Box>
      {/*
        The image section hidden on wide devices
        The image section is also hidden in a panel center configuration,
        since the image is displayed as background image, and not as a section anymore
      */}
      {landingConfiguration.panelPosition !== PanelPosition.Center && (
        <Hidden xsDown>
          <Box className={classes.image} />
        </Hidden>
      )}
      <EditionModal />
    </StyledLandingPageLayoutWrapper>
  );
};

export default getCustomerOverrideComponent(LayoutOverrideKey.LandingPageLayout, LandingPageLayout);
