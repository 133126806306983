import { FC } from "react";

import { BaseComponentProps, KButton, SanitizeHtml } from "@keepeek/refront-components";
import { Box, ButtonBase, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { KIcon } from "../../common/KIcon";

export type KKeycloakCredentialAuthButtonProps = {
  loginUrl: string;
  headBand?: boolean;
} & BaseComponentProps;

/**
 * Keepeek landing page credential auth button (no sso)
 */
const KKeycloakCredentialAuthButton: FC<KKeycloakCredentialAuthButtonProps> = function ({
  className,
  loginUrl,
  headBand = true,
}) {
  const { t } = useTranslation();

  if (headBand) {
    return (
      <ButtonBase
        focusRipple
        className={clsx("KKeycloakCredentialAuthButton", className)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 4,
          backgroundColor: (theme: Theme) => theme.palette.grey[100],
          borderTop: (theme: Theme) => `1px solid ${theme.palette.grey[400]}`,
        }}
        href={loginUrl}
      >
        <Box paddingRight={2}>
          <Typography
            component="span"
            variant="h6"
            sx={{
              "&&": {
                fontSize: "1rem",
                lineHeight: "1.1rem",
                fontWeight: "bold",
                marginBottom: 1.1,
              },
            }}
            display={"block"}
            align={"left"}
          >
            <SanitizeHtml html={t("landing.page.external.auth.headBand.title")} />
          </Typography>
          <Typography
            component="span"
            variant="subtitle1"
            sx={{
              "&&": {
                fontSize: "0.85rem",
                lineHeight: "0.9rem",
                color: "text.secondary",
              },
            }}
            display={"block"}
            align={"left"}
          >
            <SanitizeHtml html={t("landing.page.external.auth.headBand.subtitle")} />
          </Typography>
        </Box>
        <KIcon>lock_open</KIcon>
      </ButtonBase>
    );
  } else {
    return (
      <Box>
        <Typography
          sx={{
            "&&": {
              fontSize: "1.3rem",
              marginBottom: 1,
            },
          }}
          variant="h3"
        >
          <SanitizeHtml html={t("landing.page.login.title")} />
        </Typography>
        <Typography
          sx={{
            "&&": {
              fontSize: "0.9rem",
              lineHeight: "1rem",
              color: "text.secondary",
              marginBottom: 1.5,
            },
          }}
          variant="subtitle2"
        >
          <SanitizeHtml html={t("landing.page.login.subtitle")} />
        </Typography>
        <KButton
          variant="outlined"
          sx={{
            padding: 1.25,
            paddingLeft: 6,
            fontWeight: "bold",
            "& .MuiButton-startIcon": {
              position: "absolute",
              left: (theme) => theme.spacing(2.5),
            },
          }}
          data-testid="connectionButton"
          size="large"
          color="primary"
          href={loginUrl}
          fullWidth
          startIcon={<KIcon>lock_open</KIcon>}
        >
          <SanitizeHtml html={t("landing.page.external.auth.button.label")} />
        </KButton>
      </Box>
    );
  }
};

export default KKeycloakCredentialAuthButton;
