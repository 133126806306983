import React, { FC } from "react";

import {
  BaseComponentProps,
  KModal,
  KModalContentSection,
  KModalHeader,
} from "@keepeek/refront-components";
import { useTranslation } from "react-i18next";

import useFrontEditModal from "../../providers/frontEdit/hooks/useFrontEditModal";
import FrontEditEditionForm from "./FrontEditEditionForm";

export type EditionModalProps = {} & BaseComponentProps;

const EditionModal: FC<EditionModalProps> = function () {
  const { isOpen, setIsOpen, currentEditKeys } = useFrontEditModal();

  const { t } = useTranslation();

  return (
    <KModal
      header={
        <KModalHeader
          onClose={() => {
            setIsOpen(false);
          }}
          title={t("front-edit.edition-modal.element-edition.label")}
        />
      }
      onClose={() => {
        setIsOpen(false);
      }}
      open={isOpen}
    >
      <KModalContentSection>
        <FrontEditEditionForm keys={currentEditKeys} />
      </KModalContentSection>
    </KModal>
  );
};

export default EditionModal;
