import { useEffect } from "react";

import { PageSecurisation } from "@keepeek/commons";
import { useSetRecoilState } from "recoil";

import { pageSecurisationAtom } from "../atoms/securisation";

export const usePageSecurisation = ({
  pageSecurisation = PageSecurisation.None,
}: {
  pageSecurisation?: PageSecurisation;
}) => {
  const setPageSecurisation = useSetRecoilState(pageSecurisationAtom);
  useEffect(() => {
    setPageSecurisation(pageSecurisation);
  }, [pageSecurisation, setPageSecurisation]);
};
