import { FC } from "react";

import {
  PageSecurisation,
  useAuthLogin,
  useIsAuthenticated,
  REFERRER_SESSION_STORAGE_ITEM_NAME,
  LS_KC_TOKEN_EXP,
} from "@keepeek/commons";
import { PageOverrideKey } from "@keepeek/refront-customers";
import dayjs from "dayjs";
import { GetServerSideProps } from "next";
import { useRouter } from "next/router";

import KKeycloakAutomaticAuth from "../components/landingPage/KKeycloakAutomaticAuth";
import KKeycloakCredentialAuthButton from "../components/landingPage/KKeycloakCredentialAuthButton";
import KKeycloakGuestAuthButton from "../components/landingPage/KKeycloakGuestAuthButton";
import KLandingHeader from "../components/landingPage/KLandingHeader";
import KLandingRequestAccount from "../components/landingPage/KLandingRequestAccount";
import {
  isKeycloakAutomaticConnectAvailable,
  isKeycloakConnectWithCredentialsEnabled,
  isKeycloakConnectWithGuestAvailable,
} from "../components/landingPage/utils";
import { CONFIGURATION_SECTION_PATH } from "../components/layout/adminMenu";
import LandingPageLayout from "../components/layout/LandingPageLayout";
import { PagesPath } from "../containers/App/utils";
import LoadingAuth from "../containers/auth/LoadingAuth";
import { getCommonPageProps } from "../lib/commonPagePropsUtil";
import { JSON_CONFIG_API_VISIBILITY } from "../lib/config/filterConfigsVisibility/models";
import { geti18nextInstance } from "../lib/i18n/prepare-i18n-server";
import { getCustomerOverrideComponent } from "../lib/overrides";
import { Global } from "../models/configuration/global";
import { Landing as LandingLayout } from "../models/configuration/layouts/landing";
import { Landing as LandingPage } from "../models/configuration/pages/landing";
import { useAuthProps } from "../providers/auth/hooks/useAuthProps";
import { usePageSecurisation } from "../providers/auth/hooks/usePageSecurisation";
import { useConfiguration } from "../providers/config/hooks";
import { useBackdoors } from "../providers/config/hooks/useBackdoors";
import useFrontEdit from "../providers/frontEdit/hooks/useFrontEdit";

import "dayjs/plugin/utc";

type LandingProps = {};

const Landing: FC<LandingProps> = function Login() {
  usePageSecurisation({ pageSecurisation: PageSecurisation.None });

  const { loginUrl, guestLoginUrl } = useAuthLogin(useAuthProps());
  const { isAuthenticated, loading } = useIsAuthenticated();
  const { keycloakConfiguration } = useConfiguration<Global>(CONFIGURATION_SECTION_PATH.GLOBAL) ?? {
    keycloakConfiguration: { displayConnectWithCredentials: true },
  };
  const { displayAppTitle, logoCentered, logoMaxHeight } = useConfiguration<LandingLayout>(
    CONFIGURATION_SECTION_PATH.LAYOUT_LANDING,
  ) ?? { displayAppTitle: false, logoCentered: false, logoMaxHeight: 5 };
  const { helpLinkHref, requestAccountHref } = useConfiguration<LandingPage>(
    CONFIGURATION_SECTION_PATH.PAGES_LANDING,
  ) ?? { helpLinkHref: "", requestAccountHref: "" };
  const { push } = useRouter();
  const { displayConnectWithCredentials } = useBackdoors();

  if (document.referrer) {
    sessionStorage.setItem(REFERRER_SESSION_STORAGE_ITEM_NAME, document.referrer);
  }

  const { frontEdit } = useFrontEdit();
  // Redirect to home if the user is authenticated and we're not in edit mode
  if (isAuthenticated && !frontEdit) {
    push(PagesPath.HOME_PAGE);
    return null;
  }

  // Redirect to redirect page if the user can have a token
  const expiration = (): number | undefined => {
    const getExpiration = localStorage.getItem(LS_KC_TOKEN_EXP);
    return isNaN(Number(getExpiration)) ? undefined : Number(getExpiration) * 1000;
  };
  if (expiration() && loading && !frontEdit) {
    const isStillValid = dayjs().isBefore(dayjs(expiration()));
    if (isStillValid) {
      return <LoadingAuth />;
    }
  }

  let mainAuthMethod: JSX.Element | null = null;
  const bottomComponents: JSX.Element[] = [];
  const loginUrlComputed = loginUrl();
  if (isKeycloakAutomaticConnectAvailable(keycloakConfiguration)) {
    mainAuthMethod = <KKeycloakAutomaticAuth helpLinkHref={helpLinkHref} />;
    if (
      isKeycloakConnectWithCredentialsEnabled({
        ...keycloakConfiguration,
        displayConnectWithCredentials:
          keycloakConfiguration?.displayConnectWithCredentials || displayConnectWithCredentials,
      }) &&
      loginUrlComputed
    ) {
      bottomComponents.push(
        <KKeycloakCredentialAuthButton loginUrl={loginUrlComputed} headBand={true} />,
      );
    }
  } else if (loginUrlComputed) {
    mainAuthMethod = <KKeycloakCredentialAuthButton loginUrl={loginUrlComputed} headBand={false} />;
  }
  if (isKeycloakConnectWithGuestAvailable(keycloakConfiguration) && guestLoginUrl) {
    bottomComponents.push(<KKeycloakGuestAuthButton loginUrl={guestLoginUrl} />);
  }
  bottomComponents.push(<KLandingRequestAccount href={requestAccountHref} />);

  return (
    <LandingPageLayout
      header={
        <KLandingHeader
          displayAppTitle={displayAppTitle}
          logoCentered={logoCentered}
          logoMaxHeight={logoMaxHeight}
        />
      }
      mainAuthMethod={mainAuthMethod}
      bottomComponents={bottomComponents}
    />
  );
};

export default getCustomerOverrideComponent(PageOverrideKey.Login, Landing);

// Load it in SSR to have the correct CSP values for KC to be allowed
export const getServerSideProps: GetServerSideProps = async function (context) {
  const [commonProps, i18next] = await Promise.all([
    getCommonPageProps(context, JSON_CONFIG_API_VISIBILITY.PUBLIC),
    geti18nextInstance(context),
  ]);
  return {
    props: { ...commonProps, title: i18next.t("common:page.connection.title") },
  };
};
