import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

const PREFIX = "KKeycloakAutomaticAuth";

export const classes = {
  title: `${PREFIX}-title`,
  subTitle: `${PREFIX}-subTitle`,
  buttonContainer: `${PREFIX}-buttonContainer`,
  button: `${PREFIX}-button`,
  helpLink: `${PREFIX}-helpLink`,
};

export const StyledKKeycloakAutomaticAuth = styled(Box)(({ theme }) => ({
  [`& .${classes.title}`]: {
    fontSize: "1.3rem",
    marginBottom: theme.spacing(1),
  },

  [`& .${classes.subTitle}`]: {
    fontSize: "0.9rem",
    lineHeight: "1rem",
    color: theme.palette.text.secondary,
    marginBottom: theme.spacing(1.5),
  },

  [`& .${classes.buttonContainer}`]: {
    marginTop: theme.spacing(1),
  },

  [`& .${classes.button}`]: {
    padding: theme.spacing(1.25),
    paddingLeft: theme.spacing(6),
    fontWeight: "bold",
    "& .MuiButton-startIcon": {
      position: "absolute",
      left: theme.spacing(2.5),
    },
  },

  [`& .${classes.helpLink}`]: {
    fontSize: "0.80rem",
    fontWeight: "bold",
    textTransform: "uppercase",
    textDecoration: "underline",
    display: "inline-block",
    marginTop: theme.spacing(2.5),
  },
}));
