import React, { FC } from "react";

import { BaseComponentProps, SanitizeHtml } from "@keepeek/refront-components";
import { Box, ButtonBase, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { KIcon } from "../../common/KIcon";

export type KLandingRequestAccountProps = {
  href?: string;
} & BaseComponentProps;

const KLandingRequestAccount: FC<KLandingRequestAccountProps> = function ({ className, href }) {
  const { t } = useTranslation();
  return href ? (
    <ButtonBase
      focusRipple
      className={clsx("KLandingRequestAccount", className)}
      href={href}
      sx={{
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        justifyContent: "space-between",
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 4,
        paddingRight: 4,
        backgroundColor: (theme: Theme) => theme.palette.grey[100],
        borderTop: (theme: Theme) => `1px solid ${theme.palette.grey[400]}`,
      }}
    >
      <Box paddingRight={2}>
        <Typography
          component="span"
          variant="h6"
          sx={{
            "&&": {
              fontSize: "1rem",
              lineHeight: "1.1rem",
              fontWeight: "bold",
            },
          }}
          display={"block"}
          align={"left"}
        >
          <SanitizeHtml html={t("landing.page.request.account.button.title")} />
        </Typography>
      </Box>
      <KIcon>person_add</KIcon>
    </ButtonBase>
  ) : null;
};

export default KLandingRequestAccount;
