import { useMemo } from "react";

import { useRouter } from "next/router";

import { QUERY_BACKDOOR } from "../models";

type UseBackdoors = {
  displayConnectWithCredentials: boolean;
  displayAdminImportConfiguration: boolean;
  displayAuthTests: boolean;
};

export const useBackdoors = (): UseBackdoors => {
  const { query } = useRouter();

  const displayConnectWithCredentials = useMemo(
    () => QUERY_BACKDOOR.DISPLAY_CONNECT_WITH_CREDENTIALS in query,
    [query],
  );

  const displayAdminImportConfiguration = useMemo(
    () => QUERY_BACKDOOR.DISPLAY_ADMIN_IMPORT_CONFIGURATION in query,
    [query],
  );

  const displayAuthTests = useMemo(() => QUERY_BACKDOOR.DISPLAY_AUTH_TESTS in query, [query]);

  return {
    displayConnectWithCredentials,
    displayAdminImportConfiguration,
    displayAuthTests,
  };
};
