import { FC } from "react";

import { BaseComponentProps, SanitizeHtml } from "@keepeek/refront-components";
import { Box, ButtonBase, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { KIcon } from "../../common/KIcon";

export type KKeycloakGuestAuthButtonProps = { loginUrl: string } & BaseComponentProps;

const KKeycloakGuestAuthButton: FC<KKeycloakGuestAuthButtonProps> = function ({
  className,
  loginUrl,
}) {
  const { t } = useTranslation();

  return (
    <ButtonBase
      focusRipple
      className={clsx("KKeycloakGuestAuthButton", className)}
      href={loginUrl}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 4,
        backgroundColor: (theme: Theme) => theme.palette.grey[100],
        borderTop: (theme: Theme) => `1px solid ${theme.palette.grey[400]}`,
        width: "100%",
      }}
    >
      <Box paddingRight={2}>
        <Typography
          component="span"
          variant="h6"
          sx={{
            "&&": {
              fontSize: "1rem",
              lineHeight: "1.1rem",
              fontWeight: "bold",
              marginBottom: 1.1,
            },
          }}
          display={"block"}
          align={"left"}
        >
          <SanitizeHtml html={t("landing.page.guest.auth.button.title")} />
        </Typography>
        <Typography
          component="span"
          variant="subtitle1"
          sx={{
            fontSize: "0.85rem",
            lineHeight: "0.9rem",
            color: "text.secondary",
          }}
          display={"block"}
          align={"left"}
        >
          <SanitizeHtml html={t("landing.page.guest.auth.button.subtitle")} />
        </Typography>
      </Box>
      <KIcon>assignment_ind</KIcon>
    </ButtonBase>
  );
};

export default KKeycloakGuestAuthButton;
