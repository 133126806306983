import { FC, SyntheticEvent, useState } from "react";

import { BaseComponentProps } from "@keepeek/refront-components";
import { Box, Typography, useTheme } from "@mui/material";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";

import KLanguageSwitcher, { showKLanguageSwitcher } from "../../../containers/KLanguageSwitcher";
import logger from "../../../lib/logger-utils";
import { Landing } from "../../../models/configuration/layouts/landing";
import { Key } from "../../../models/configuration/theme";
import { useConfiguration } from "../../../providers/config/hooks";
import { configSectionThemeAssetSelector } from "../../../providers/config/selectors";
import useFrontEdit from "../../../providers/frontEdit/hooks/useFrontEdit";
import { getCurrentContentEditsSelector } from "../../../providers/frontEdit/selectors/currentContentEdits";
import { CONFIGURATION_SECTION_PATH } from "../../layout/adminMenu";
import { classes, StyledKLandingHeader } from "./styles";

export type KLandingHeaderProps = {
  displayAppTitle: boolean | undefined;
  logoCentered: boolean | undefined;
  logoMaxHeight: number | undefined;
} & BaseComponentProps;

/**
 * Keepeek landing page header
 */
const KLandingHeader: FC<KLandingHeaderProps> = function ({
  displayAppTitle,
  logoCentered,
  logoMaxHeight,
  className,
}) {
  const { locales } = useRouter();

  const theme = useTheme();
  const { t } = useTranslation();
  const [logoUrl] = useRecoilValue(configSectionThemeAssetSelector(Key.ImageLogoLogin));
  const landingConfiguration = useConfiguration<Landing>(CONFIGURATION_SECTION_PATH.LAYOUT_LANDING);
  let logoLoginSrc: string | undefined = logoUrl;
  const { frontEdit } = useFrontEdit();

  const currentContentEdit = useRecoilValue(
    getCurrentContentEditsSelector({
      endpoint: CONFIGURATION_SECTION_PATH.LAYOUT_LANDING,
    }),
  );
  if (landingConfiguration?.logoLogin) {
    logoLoginSrc = landingConfiguration.logoLogin.url;
  }
  if (frontEdit && currentContentEdit) {
    logoLoginSrc = currentContentEdit.data.logoLogin.url;
  }

  const [imageOnError, setImageOnError] = useState(false);
  const handleImageOnError = (e: SyntheticEvent<HTMLImageElement, Event>) => {
    setImageOnError(true);
    logger.warn("KLandingHeader - logoLoginSrc in error", e);
  };

  return (
    <StyledKLandingHeader
      className={clsx("KLandingHeader", classes.root, className)}
      style={!logoCentered ? { gridTemplateColumns: "2fr 1fr", justifyItems: "flex-start" } : {}}
    >
      {/* logo with title */}
      <Box
        component="h1"
        className={classes.logoContainer}
        style={!logoCentered ? { gridColumnStart: 1 } : {}}
      >
        {logoLoginSrc && (
          <>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              src={logoLoginSrc}
              alt={t("app.title") || ""}
              style={{
                maxHeight: theme.spacing(logoMaxHeight || 5),
                width: "100%",
                display: imageOnError ? "none" : "inline-block",
              }}
              aria-hidden={imageOnError}
              onError={(e) => handleImageOnError(e)}
            />
            {imageOnError && !displayAppTitle && (
              <Box
                sx={{
                  position: "absolute",
                  width: "1px",
                  height: "1px",
                  padding: 0,
                  margin: "-1px",
                  overflow: "hidden",
                  clip: "rect(0, 0, 0, 0)",
                  whiteSpace: "nowrap" /* added line */,
                  border: 0,
                }}
              >
                {t("app.title") || ""}
              </Box>
            )}
          </>
        )}
        {displayAppTitle && !logoCentered && (
          <Typography className={classes.title} variant="h1" component="div">
            {t("app.title")}
          </Typography>
        )}
      </Box>
      {showKLanguageSwitcher(locales) && (
        <Box
          className={classes.kLanguageSwitcherContainer}
          style={!logoCentered ? { gridColumnStart: 2 } : {}}
        >
          <KLanguageSwitcher className={classes.kLanguageSwitcher} />
        </Box>
      )}
    </StyledKLandingHeader>
  );
};

export default KLandingHeader;
